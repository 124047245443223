"use strict";

document.addEventListener('DOMContentLoaded', function () {
  var galleryImage = new Swiper(".gallery-image", {
    direction: "horizontal",
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-product-next",
      prevEl: ".swiper-product-prev"
    },
    a11y: {
      prevSlideMessage: "Previous slide",
      nextSlideMessage: "Next slide"
    },
    keyboard: {
      enabled: true
    }
  });
});