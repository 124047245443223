"use strict";

// Function to initialize Swiper
function initSwiper() {
  return new Swiper('.swiper-container', {
    loop: true,
    slidesPerView: 2,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    },
    breakpoints: {
      1100: {
        slidesPerView: 4
      }
    }
  });
}
function handleResize() {
  var mobileBreakpoint = 1300; // Define the breakpoint for mobile
  var screenWidth = window.innerWidth;
  if (screenWidth < mobileBreakpoint && !swiperInstance) {
    swiperInstance = initSwiper();
  } else if (screenWidth >= mobileBreakpoint && swiperInstance) {
    swiperInstance.destroy(true, true);
    swiperInstance = null; // Reset the swiper instance
  }
}
var swiperInstance;
window.addEventListener('resize', handleResize);
window.addEventListener('load', handleResize);