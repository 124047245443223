"use strict";

document.addEventListener('DOMContentLoaded', function () {
  $(document).foundation();
  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    // disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: true
  });
  var closePopupBtn = $('#closePopup');
  var nav = document.getElementById('site-navigation');
  var menuBtn = document.querySelector('.menu-btn');
  var lines = document.querySelectorAll('.menu-btn .line');
  var ul = document.querySelectorAll("nav#site-navigation ul li ul");
  var li = document.querySelectorAll("nav#site-navigation ul li ul li");
  var openSearchBtn = document.querySelector('.open-search-from');
  var deleteSearchBtn = document.querySelector('#deleteSearch');
  var fadeElements = document.querySelectorAll('.fade-in');
  var header = document.querySelector('.site-header');
  var navbarHeight = header.offsetHeight;
  var openQuoteBtn = $('.openQuote');
  var didScroll = false;
  var lastScrollTop = 0;
  var delta = 5;
  var popup = $('.popup-container');
  if (openQuoteBtn.length) {
    openQuoteBtn.click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 50);
      popup.css("display", "flex").hide().fadeIn();
    });
  }
  if (closePopupBtn.length) {
    closePopupBtn.click(function () {
      popup.fadeOut();
    });
  }
  $(document).keyup(function (e) {
    if (e.key === "Escape") {
      popup.fadeOut();
    }
  });
  menuBtn.addEventListener('click', function () {
    nav.classList.toggle('open-nav');
    header.classList.toggle('open-nav');
    menuBtn.classList.toggle('menu-open');
    lines.forEach(function (line) {
      return line.classList.toggle('line-cross');
    });
    lines[1].classList.toggle('line-fade-out');
  });
  function isScrolledIntoView(elem) {
    var docViewTop = window.scrollY;
    var docViewBottom = docViewTop + window.innerHeight + 200;
    var elemTop = elem.offsetTop;
    var elemBottom = elemTop + elem.offsetHeight;
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
  ul.forEach(function (ul) {
    if (ul.classList.contains('children')) {
      ul.parentNode.classList.add('hasChildren');
    }
  });
  li.forEach(function (li) {
    if (li.classList.contains('hasChildren')) {
      li.querySelector('a').insertAdjacentHTML('beforeend', "<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
    }
  });
  document.addEventListener('click', function (e) {
    if (!nav.contains(e.target)) {
      nav.classList.remove('nav-open');
    }
  });
  openSearchBtn.addEventListener('click', function () {
    document.querySelector('.top-search').classList.add('open-search');
    document.body.classList.add('search-main');
  });
  deleteSearchBtn.addEventListener('click', function () {
    document.querySelector('.top-search').classList.remove('open-search');
    document.body.classList.remove('search-main');
  });
  window.addEventListener('scroll', function () {
    fadeElements.forEach(function (element) {
      if (isScrolledIntoView(element)) {
        element.classList.add('animation-started');
      }
    });
    didScroll = true;
  });
  jQuery('.footer-label').click(function ($) {
    jQuery(this).closest('.footer-menu').find('.footer-container ul').slideToggle();
    jQuery(this).closest('.footer-menu').find('i').toggleClass('open');
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = window.scrollY;
    if (Math.abs(lastScrollTop - st) <= delta) return;
    if (st > lastScrollTop && st > navbarHeight) {
      header.classList.remove('nav-down');
      header.classList.add('nav-up');
    } else {
      if (st + window.innerHeight < document.body.offsetHeight) {
        header.classList.remove('nav-up');
        header.classList.add('nav-down');
      }
    }
    lastScrollTop = st;
  }
  var distance = $('header').offset().top;
  $(window).scroll(function () {
    if ($(this).scrollTop() >= distance) {
      jQuery('header').addClass('not-on-top').removeClass('on-top');
      jQuery('#page').addClass('not-on-top').removeClass('on-top');
    } else {
      jQuery('header').addClass('on-top').removeClass('not-on-top');
      jQuery('#page').addClass('on-top').removeClass('not-on-top');
    }
  });
});